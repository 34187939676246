/* TW Elements is free under AGPL, with commercial license required for specific uses. See more details: https://tw-elements.com/license/ and contact us for queries at tailwind@mdbootstrap.com */
/* @import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@100;200;300;400;500;600;700&display=swap");
@tailwind base;

@layer base {
  button, :not(nav) > a {
    @apply border border-white hover:border-green-400 hover:bg-white hover:text-black shadow-md rounded-md px-5 py-1
  }
  select:focus,
  textarea:focus,
  input:focus {
    outline-color: #4ade80;
    outline-width: 1px;
    outline-style: dashed
  }

  html {
    @apply text-neutral-800;
  }

  html.dark {
    @apply text-neutral-50;
    @apply bg-neutral-800;
  }
}

@tailwind components;
@tailwind utilities;