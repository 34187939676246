:root {
  --primarycolor: #35cc86;
  --secondarycolor: #04ac42;
}
.banner-shadow-ltr{
  background: linear-gradient(to right, #35cc86, #00000000);
}
.banner-shadow-rtl{
  background: linear-gradient(to left, #35cc86, #00000000);
}
.primary-color{ color: var(--primarycolor); }
.secondary-color { color: var(--secondarycolor); }
.bg-primary-color { background-color: var(--primarycolor); }
.bg-secondary-color { background-color: var(--secondarycolor); }
.border-primary-color { border-color: var(--primarycolor); }
.border-secondary-color { border-color: var(--secondarycolor); }
h1, h2, h3, h4, h5, h6 { font-weight: bolder; }
h1 { font-size: 2em; }
h2 { font-size: 1.5em; }
h3 { font-size: 1.17em; }
h4 { font-size: 1em; }
h5 { font-size: 0.83em; }
h6 { font-size: 0.67em; }

.header-info { background-size: 30%; background-repeat: no-repeat; }
.header-info.ltr{ background-image: url("./img/header-lbg.svg"); background-position: left 0 }
.header-info.rtl { background-image: url("./img/header-rbg.svg"); background-position: right 0 }
nav a {
  position: relative;
  color: white;
  padding: 10px 15px;
  font-size: 14px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}
nav.rtl a{
  border-bottom-left-radius: initial;
  border-top-left-radius: initial;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

nav a:hover,
nav a.active {
  margin-inline-end: -32px;
  background-color: white;
  color: var(--primarycolor);
  transition-duration: 0.25s;
}
nav a:hover::before,
nav a.active::before {
  content: '';
  position: absolute;
  right: 0;
  width: 30px;
  height: 30px;
  top: -30px;
  background: transparent;
  border-bottom-right-radius: 10px;
  box-shadow: 5px 5px 0 1px white;
}
nav.rtl a:hover::before,
nav.rtl a.active::before{
  left: 0;
  right: initial;
  border-bottom-right-radius: initial;
  border-bottom-left-radius: 10px;
  box-shadow: -5px 5px 0 1px white;
}
nav a:hover::after,
nav a.active::after {
  content: '';
  position: absolute;
  bottom: -30px;
  width: 30px;
  height: 30px;
  right: 0;
  background: transparent;
  border-top-right-radius: 10px;
  box-shadow: 5px -5px 0 1px white;
}
nav.rtl a:hover::after,
nav.rtl a.active::after {
  left: 0;
  right: initial;
  border-top-right-radius: initial;
  border-top-left-radius: 10px;
  box-shadow: -5px -5px 0 1px white;
}
.product-details img:hover {
  transition-duration: 0.25s;
  scale: 1.05;
  transition-timing-function: linear;
}
.options form {
  transition-duration: 0.25s;
  transition-timing-function: linear;
}
.nav-height{
  min-height: calc(100vh - 12.3vh);
}
.body_bg {
  background: url(img/body_bg.svg) #f8717118 no-repeat;
}

.transparent_bg {
  background-color: #ebf9f0;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='88' height='24' viewBox='0 0 88 24'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='autumn' fill='%234ade80' fill-opacity='0.05'%3E%3Cpath d='M10 0l30 15 2 1V2.18A10 10 0 0 0 41.76 0H39.7a8 8 0 0 1 .3 2.18v10.58L14.47 0H10zm31.76 24a10 10 0 0 0-5.29-6.76L4 1 2 0v13.82a10 10 0 0 0 5.53 8.94L10 24h4.47l-6.05-3.02A8 8 0 0 1 4 13.82V3.24l31.58 15.78A8 8 0 0 1 39.7 24h2.06zM78 24l2.47-1.24A10 10 0 0 0 86 13.82V0l-2 1-32.47 16.24A10 10 0 0 0 46.24 24h2.06a8 8 0 0 1 4.12-4.98L84 3.24v10.58a8 8 0 0 1-4.42 7.16L73.53 24H78zm0-24L48 15l-2 1V2.18A10 10 0 0 1 46.24 0h2.06a8 8 0 0 0-.3 2.18v10.58L73.53 0H78z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.login_svg_bg {
  background: url(img/login_bg.svg) no-repeat;
  background-position: center 130px;
  background-size: contain;
}

.otp_svg_bg {
  background: url(img/otp_bg.svg) no-repeat;
  background-position: center 180px;
  background-size: contain;
}

.otp-style{
  letter-spacing: 52px;
  color: transparent;
  text-shadow: 0 0 0 #000000;
  outline-width: 2px;
}
p.form-error-msg {
  display: none;
}
textarea:invalid[blured="true"],
input:invalid[blured="true"] {
  border: none;
  border: 1px dashed red;
}
textarea:invalid[blured="true"] ~ p,
input:invalid[blured="true"] ~ p {
  display: block;
}

@media (max-width: 1100px) {

  nav a.active::before,
  nav a.active::after {
    display: none;
  }

  nav a {
    border-radius: 10px;
  }

  nav a.active,
  nav a:hover {
    margin-bottom: 0;
  }
}